import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/Components/ui/dialog"
import { cn } from "@/lib/utils";

export default function Modal({ title = "", children, show = false, className = "", closable = true, onClose = () => { } }) {
    const close = () => closable && onClose();

    return (
        <Dialog open={show} onOpenChange={close}>
            <DialogContent closable={closable} className={cn("!z-[999999] flex flex-col", className)}>
                <DialogHeader className="h-[40px]">
                    <DialogTitle>{title}</DialogTitle>
                </DialogHeader>
                {children}
            </DialogContent>
        </Dialog>
    )
}

